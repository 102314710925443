
import { View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class PrivacyPolicy extends Mixins(View) {
  private businessName: string | null = null;

  mounted() {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.businessName = office.FranchiseName;
    });
  }
}
